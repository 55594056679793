<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text pt-0">
        <v-col cols="8" class="pt-0 pb-0"
          >Importación de tabla de facturación</v-col
        >
        <v-col cols="4" class="pt-0 pb-0">
          <v-btn
            color="primary"
            class="no-upper-case to-right"
            outlined
            @click="openModalSeeFormatoArchivo()"
          >
            Ver formato
          </v-btn></v-col
        >
      </v-card-title>
      <v-card-subtitle v-if="ctaFacCount > 0">
        <v-col class="py-0">
          <span class="red--text py-0">
            <v-icon color="red" class="pt-0 pb-0" small>
              {{ infoIcon }}
            </v-icon>
            El conjunto de prácticas actual se borrará al hacer la importación.
          </span>
        </v-col>
      </v-card-subtitle>
      <v-form
        v-model="isFormValidImportar"
        ref="form-import"
        form="form-import"
        id="form-import"
      >
        <!-- Proceso de importación -->
        <v-row cols="10" class="py-0">
          <v-col cols="7" class="py-0">
            <v-file-input
              ref="file"
              v-model="excelFile"
              :disabled="loadingFile"
              :loading="loadingFile"
              id="file"
              type="file"
              dense
              label="Excel"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              @change="readExcel"
              :rules="rules.required"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loadingFile ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-spacer></v-spacer>

          <v-col
            cols="5"
            sm="12"
            md="5"
            class="d-flex py-0 justify-content-center"
          >
            <v-switch
              v-model="creaUnidades"
              id="switch1"
              class="mt-1 "
            ></v-switch>
            <label for="switch1" class="mt-2"
              >Crear unidades si no existen</label
            >
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-data-table
            :headers="headersImport"
            :items="excelDataTranslated"
            class="elevation-1"
            :hide-default-footer="true"
            dense
          >
          </v-data-table>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValidImportar || loadingFile"
          form="form-import"
          :loading="loadingFile"
          color="primary"
          @click.stop.prevent="saveImportar()"
        >
          Importar
        </v-btn>
      </v-card-actions>
    </v-container>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="40%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se encontraron errores en el archivo</span
            >
          </v-card-title>
          <v-card-subtitle>
            <span class="pl-1 primary--text">
              No se realizó la importación.
            </span>
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="to-right"
              @click="modalExportarExcel = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              outlined
              color="primary"
              class="to-right"
              @click="exportExcelError()"
            >
              Exportar detalle
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <FormatoArchivoDialog
      :formatoTitle="formatoTitle"
      :headers="headersFormato"
      :datosFormatoArchivo="datosFormatoArchivo"
      :dataToExport="dataToExport"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";
import { mask } from "vue-the-mask";
import helpersExcel from "@/utils/helpers/importExportExcel";
import * as XLSX from "xlsx";

export default {
  name: "ImportarTab",
  directives: { mask },
  props: {
    tabla: { type: Object, required: false, default: null },
    tabId: { type: Number, required: true },
    ctaFacCount: { type: Number, required: false, default: 0 }
  },
  components: { CurrencyInput, FormatoArchivoDialog },
  data: () => ({
    infoIcon: enums.icons.SNB_INFO,
    showSeeFormatModal: false,
    modalExportarExcel: false,
    rules: rules,
    excelDataTranslated: [],
    registrosError: [],
    isFormValidImportar: false,
    excelData: [],
    excelFile: null,
    loadingFile: false,
    archivoEnProceso: false,
    creaUnidades: false,
    dataToExport: {
      formatoName: "Formato importación tabla de facturación",
      data: null
    },
    formatoTitle: "Formato de archivo",
    headersFormato: [
      {
        text: "Nombre campo",
        align: "start",
        sortable: false,
        value: "nombreCampo"
      },
      {
        text: "Orden campo",
        align: "start",
        sortable: false,
        value: "ordenCampo"
      },
      {
        text: "Longitud máxima",
        align: "start",
        sortable: false,
        value: "longMax"
      },
      {
        text: "Formato",
        value: "formato",
        align: "start",
        sortable: false
      },
      {
        text: "Requerido",
        value: "requerido",
        align: "center",
        sortable: false
      },
      {
        text: "Descripción",
        value: "descripcion",
        align: "start",
        sortable: false
      }
    ],
    datosFormatoArchivo: [],
    headersImport: [
      {
        text: "Nomenclador",
        value: "nomenclador",
        sortable: false
      },
      {
        text: "Código desde",
        value: "codigoDesde",
        sortable: false
      },
      {
        text: "Código hasta",
        value: "codigoHasta",
        sortable: false
      },
      {
        text: "Área",
        value: "area",
        sortable: false
      },
      {
        text: "Importe especialista",
        value: "ctaEspecialista",
        align: "end",
        sortable: false
      },
      {
        text: "Importe ayudante",
        value: "ctaAyudante",
        align: "end",

        sortable: false
      },
      {
        text: "Importe anestesista",
        value: "ctaAnestesista",
        align: "end",
        sortable: false
      },
      {
        text: "Importe gastos",
        value: "ctaGastos",
        align: "end",
        sortable: false
      },
      {
        text: "Unidad especialista",
        value: "uniEspecialista",
        sortable: false
      },
      {
        text: "Unidad ayudante",
        value: "uniAyudante",
        sortable: false
      },
      {
        text: "Unidad anestesista",
        value: "uniAnestesista",
        sortable: false
      },
      {
        text: "Unidad gastos",
        value: "uniGastos",
        sortable: false
      }
    ],
    importeTablas: []
  }),
  methods: {
    ...mapActions({
      importaTablaFacturacion: "prestadores/importaTablaFacturacion",
      getFormatoProcesoImportacionXUnicaVez:
        "devengamientos/getFormatoProcesoImportacionXUnicaVez",
      exportTablaFacturacionImportacion:
        "prestadores/exportTablaFacturacionImportacion",
      fetchTiposProcesosFormatos: "prestadores/fetchTiposProcesosFormatosById",
      setAlert: "user/setAlert"
    }),
    //Modal importar

    async saveImportar() {
      this.loadingFile = true;
      const camposInvalidos = this.excelDataTranslated.some(
        ({ ctaEspecialista, ctaAnestesista, ctaAyudante, ctaGastos }) => {
          return (
            isNaN(parseFloat(ctaEspecialista)) ||
            isNaN(parseFloat(ctaAnestesista)) ||
            isNaN(parseFloat(ctaAyudante)) ||
            isNaN(parseFloat(ctaGastos))
          );
        }
      );
      if (camposInvalidos) {
        this.setAlert({
          type: "warning",
          message: "Solo se permiten valores numéricos en los campos de importe"
        });
        this.loadingFile = false;
        return;
      }

      const data = {
        tabId: this.tabId,
        creaUnidadesInexistentes: this.creaUnidades,
        ArchivoImportacion: this.excelDataTranslated
      };
      try {
        const response = await this.importaTablaFacturacion(data);

        this.registrosError = response.data.data;
        this.loadingFile = false;

        if (this.registrosError.length > 0) {
          this.modalExportarExcel = true;
          this.loadingFile = false;
        } else {
          this.setAlert({
            type: "success",
            message: "Tabla importada con éxito."
          });
          this.loadingFile = false;
          this.$emit("setPractica");
          this.$emit("setAranceles");
          this.$emit("closeModalImportar");
        }
      } catch {
        this.loadingFile = false;
      }
    },

    getArchivo(rowsToIgnore) {
      // si el loadingFile es false, significa que es la primera vez que llama al metodo
      if (this.archivoEnProceso == false) {
        this.loadingFile = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate()) {
          this.excelData = helpersExcel.excelImport(
            this.excelFile,
            rowsToIgnore
          );
        }
      }
    },
    async readExcel() {
      if (this.excelFile) {
        try {
          const expectedHeaders = [
            "Nomenclador",
            "Codigo desde",
            "Codigo hasta",
            "Area",
            "Importe especialista",
            "Importe ayudante",
            "Importe anestesista",
            "Importe gastos",
            "Unidad especialista",
            "Unidad ayudante",
            "Unidad anestesista",
            "Unidad gastos"
          ];
          const validHeaders = await helpersExcel.validarCabecerasExcel(
            this.excelFile,
            expectedHeaders
          );

          if (!validHeaders) {
            this.loadingFile = false;
            this.$refs["file"].reset();
            return;
          }
          this.loadingFile = true;
          this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.fileName = this.excelFile.name;
            this.excelDataTranslated = [];

            this.excelData[0]?.filas.forEach(x => {
              this.excelDataTranslated.push({
                nomenclador: x["Nomenclador"],
                codigoDesde: x["Codigo desde"],
                codigoHasta: x["Codigo hasta"],
                area: x["Area"],
                ctaEspecialista: parseFloat(x["Importe especialista"]),
                ctaAyudante: parseFloat(x["Importe ayudante"]),
                ctaAnestesista: parseFloat(x["Importe anestesista"]),
                ctaGastos: parseFloat(x["Importe gastos"]),
                uniEspecialista: x["Unidad especialista"],
                uniAyudante: x["Unidad ayudante"],
                uniAnestesista: x["Unidad anestesista"],
                uniGastos: x["Unidad gastos"]
              });
            });

            this.loadingFile = false;
          } else {
            setTimeout(this.readExcel, 2000);
          }
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.loadingFile = false;
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
        }
      }
    },
    closeModal() {
      this.$emit("closeModalImportar");
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },

    async openModalSeeFormatoArchivo() {
      const formatoProceso = await this.fetchTiposProcesosFormatos(34);
      this.datosFormatoArchivo = formatoProceso;
      const nuevoObjeto = formatoProceso.reduce((obj, campo) => {
        obj[campo.nombreCampo] = campo.datoEjemplo;
        return obj;
      }, {});
      nuevoObjeto.Descripcion = "";

      const array1 = [
        "Prestaciones Médicas",
        10104,
        10104,
        "D",
        0,
        0,
        0,
        8415.79,
        "",
        "",
        "",
        "",
        "Esto es modulado o valor Fijo"
      ];

      const array2 = [
        "Prestaciones Médicas",
        10105,
        10105,
        "D",
        0,
        0,
        0,
        0,
        "Galeno Quirúrgico",
        "Galeno",
        "Galeno",
        "Gastos Quirúrgico",
        "Esto es acto médico"
      ];

      const objeto1 = array1.reduce((obj, value, index) => {
        const campo = Object.keys(nuevoObjeto)[index];
        obj[campo] = value;
        return obj;
      }, {});

      const objeto2 = array2.reduce((obj, value, index) => {
        const campo = Object.keys(nuevoObjeto)[index];
        obj[campo] = value;
        return obj;
      }, {});

      const resultado = [objeto1, objeto2];
      this.dataToExport.data = resultado;
      this.showSeeFormatModal = true;
    },
    exportExcelError() {
      let result = [];
      this.registrosError.forEach(x =>
        result.push({
          ["Nomenclador"]: x.nomenclador,
          ["Codigo desde"]: x.codigoDesde,
          ["Codigo hasta"]: x.codigoHasta,
          ["Area"]: x.area,
          ["Importe especialista"]: x.ctaEspecialista,
          ["Importe ayudante"]: x.ctaAyudante,
          ["Importe anestesista"]: x.ctaAnestesista,
          ["Importe gastos"]: x.ctaGastos,
          ["Unidad especialista"]: x.uniEspecialista,
          ["Unidad ayudante"]: x.uniAyudante,
          ["Unidad anestesista"]: x.uniAnestesista,
          ["Unidad gastos"]: x.uniGastos,
          ["Error"]: x.error
        })
      );
      let formato = {
        filas: result,
        hoja: "Errores importacion"
      };
      helpersExcel.excelExport(formato, "Errores importacion");
      this.closeModal();
    }
  }
};
</script>
<style scoped></style>
