var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(" Heredar tabla ")]),_c('v-form',{ref:"form-heredar",attrs:{"form":"form-heredar","id":"form-heredar"},on:{"submit":function($event){$event.preventDefault();return _vm.applySave()}},model:{value:(_vm.isFormValidHeredar),callback:function ($$v) {_vm.isFormValidHeredar=$$v},expression:"isFormValidHeredar"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{ref:"tablas-autocomplete",attrs:{"items":_vm.nombreTablas,"search-input":_vm.tablasAutocompleteInput,"item-text":"value","item-value":"id","filter":_vm.customFilterTablas,"outlined":"","rules":_vm.rules.required,"hide-no-data":"","hint":"Ingrese número o nombre de tabla","clearable":"","cache-items":"","return-object":"","dense":"","autocomplete":"off","label":"Nombre de tabla"},on:{"update:searchInput":function($event){_vm.tablasAutocompleteInput=$event},"update:search-input":function($event){_vm.tablasAutocompleteInput=$event},"change":function($event){return _vm.tablaChange(_vm.nombreTablaSelected)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value))])],1)]}},{key:"append",fn:function(){return [(_vm.tablasLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}]),model:{value:(_vm.nombreTablaSelected),callback:function ($$v) {_vm.nombreTablaSelected=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreTablaSelected"}})],1),_c('v-col',{staticClass:"py-0 my-0 d-flex justify-center",attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.tipoRedondeo,"return-object":"","item-text":"value","item-value":"id","outlined":"","dense":"","autocomplete":"off","label":"Tipo de redondeo"},model:{value:(_vm.tipoRedondeoSelected),callback:function ($$v) {_vm.tipoRedondeoSelected=$$v},expression:"tipoRedondeoSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0 my-0 d-flex justify-center",attrs:{"cols":"6","md":"5"}},[_c('currency-input',{attrs:{"append-icon":_vm.porcentajeIcon,"dense":"","options":_vm.currencyOptions,"rules":_vm.porcentajeAumentoFac != null
                ? 
                    _vm.rules.maxDecimal100()
                  
                : [],"label":"Porcentaje de aumento prácticas","outlined":""},model:{value:(_vm.porcentajeAumentoFac),callback:function ($$v) {_vm.porcentajeAumentoFac=$$v},expression:"porcentajeAumentoFac"}})],1),_c('v-col',{staticClass:"py-0 my-0 d-flex justify-center",attrs:{"cols":"6","md":"5"}},[_c('currency-input',{attrs:{"append-icon":_vm.porcentajeIcon,"dense":"","options":_vm.currencyOptions,"rules":_vm.porcentajeAumentoAran != null
                ? 
                   _vm.rules.maxDecimal100()
                  
                : [],"label":"Porcentaje de aumento aranceles","outlined":""},model:{value:(_vm.porcentajeAumentoAran),callback:function ($$v) {_vm.porcentajeAumentoAran=$$v},expression:"porcentajeAumentoAran"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValidHeredar,"form":"form-heredar","color":"primary","loading":_vm.loadingBtn}},[_vm._v(" Aplicar ")])],1),_c('ConfirmDialog',{attrs:{"text":_vm.textConfirmDialog,"openConfirm":_vm.openConfirmDialog},on:{"update:openConfirm":function($event){_vm.openConfirmDialog=$event},"update:open-confirm":function($event){_vm.openConfirmDialog=$event},"onConfirm":function($event){return _vm.applyHeredar()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }