<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="applyPractica()"
        >
          <v-row class="py-0">
            <v-col cols="6" sm="6" md="4" class="ml-2">
              <v-autocomplete
                type="text"
                outlined
                dense
                label="Área"
                v-model="areaSelected"
                :items="itemArea"
                item-text="value"
                item-value="id"
                autocomplete="off"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="5">
              <v-autocomplete
                v-model="nomencladorSelected"
                :items="nomencladorItems"
                item-text="value"
                item-value="id"
                autocomplete="on"
                label="Nomenclador"
                outlined
                return-object
                @change="changeNomenclador"
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <v-row>
            <!-- Codigo desde -->
            <v-col cols="12" class="py-0 ml-2" md="4">
              <v-text-field
                v-model.trim="codigoDesde"
                type="text"
                outlined
                dense
                clearable
                :disabled="nomencladorSelected == null"
                label="Código desde"
                :rules="
                  rules.requiredAcceptZero.concat(
                    rules.validDateRange(
                      parseInt(codigoDesde),
                      parseInt(codigoHasta)
                    )
                  )
                "
                v-mask="'#########'"
                autocomplete="off"
                @change="buscarCodigoDesde"
              ></v-text-field>
            </v-col>
            <!-- Descripcion codigo desde -->
            <v-col cols="12" class="py-0" md="6">
              <v-text-field
                v-model.trim="descCodigoDesde"
                type="text"
                outlined
                dense
                clearable
                disabled
                label="Descripción código desde"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Boton buscar codigo desde -->
            <v-col cols="1" class="py-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    color="primary"
                    :disabled="nomencladorSelected == null"
                    @click="modalBusquedaCodigoDesde = true"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar "código desde" a través de la descripción</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <!-- Codigo hasta -->
            <v-col cols="12" class="py-0 ml-2" md="4">
              <v-text-field
                v-model.trim="codigoHasta"
                type="text"
                outlined
                dense
                clearable
                :disabled="nomencladorSelected == null"
                label="Código hasta"
                :rules="
                  rules.requiredAcceptZero.concat(
                    rules.validDateRange(
                      parseInt(codigoDesde),
                      parseInt(codigoHasta)
                    )
                  )
                "
                v-mask="'#########'"
                @change="buscarCodigoHasta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Descripcion codigo hasta -->
            <v-col cols="12" class="py-0" md="6">
              <v-text-field
                v-model.trim="descCodigoHasta"
                type="text"
                outlined
                dense
                clearable
                disabled
                label="Descripción código hasta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Boton buscar codigo desde -->
            <v-col cols="1" class="py-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    color="primary"
                    :disabled="nomencladorSelected == null"
                    @click="modalBusquedaCodigoHasta = true"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar "código hasta" a través de la descripción</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              sm="6"
              md="4"
              class="py-0 my-0 d-flex justify-center ml-2"
            >
              <v-select
                v-model="tipoimporteEspSelected"
                :items="tipoImpEsp"
                item-text="value"
                item-value="id"
                @change="changeImporteEsp"
                outlined
                dense
                return-object
                label="Tipo importe especialista"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="py-0 ">
              <v-text-field
                v-model="importeEsp"
                :disabled="
                  tipoimporteEspSelected == 'N' ||
                    tipoimporteEspSelected.value == 'No factura'
                "
                dense
                outlined
                v-show="tipoimporteEspSelected.id !=='U'"
                label="Importe especialista"
                :prefix="
                  tipoimporteEspSelected.id !== 'I' 
                    ? ''
                    : '$'
                "
                :rules="tipoimporteEspSelected.id === 'I' ? rules.required.concat(rules.decimalSi) : []"
              ></v-text-field>
              <!-- <div v-if="tipoimporteEspSelected.id == 'U'"> -->
              <v-select
                v-model="unidadEsp"
                :items="itemUnidades"
                v-show="tipoimporteEspSelected.id == 'U'"
                item-text="value"
                item-value="id"
                @change="changeImporteEsp"
                outlined
                dense
                return-object
                label="Unidad de especialista"
              >
              </v-select>
              <!-- </div> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              sm="6"
              md="4"
              class="py-0 my-0 d-flex justify-center ml-2"
            >
              <v-select
                v-model="tipoImporteAyuSelected"
                :items="tipoImpAyu"
                return-object
                @change="changeImporteAyu"
                item-text="value"
                item-value="id"
                outlined
                dense
                label="Tipo importe ayudante"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="importeAyudante"
                :disabled="
                  tipoImporteAyuSelected == 'N' ||
                    tipoImporteAyuSelected.value == 'No factura'
                "
                label="Importe ayudante"
                dense
                v-show="tipoImporteAyuSelected.id !=='U'"
                outlined
                :prefix="
                  tipoImporteAyuSelected.id !== 'I' 
                    ? ''
                    : '$'
                "
                :rules="
                  tipoImporteAyuSelected.id === 'I'
                    ? rules.required.concat(rules.decimalSi)
                    : []
                "
              ></v-text-field>
              <v-select
                v-model="unidadAyu"
                :items="itemUnidades"
                v-show="tipoImporteAyuSelected.id == 'U'"
                item-text="value"
                item-value="id"
                @change="changeImporteEsp"
                outlined
                dense
                return-object
                label="Unidad de ayudante"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              sm="6"
              md="4"
              class="py-0 my-0 d-flex justify-center ml-2"
            >
              <v-select
                v-model="tipoImpAnesteSelected"
                :items="tipoImpAnestesia"
                return-object
                item-text="value"
                item-value="id"
                @change="changeImporteAne"
                outlined
                dense
                autocomplete="off"
                label="Tipo importe anestesista"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="importeAnestesista"
                :disabled="
                  tipoImpAnesteSelected == 'N' ||
                    tipoImpAnesteSelected.value == 'No factura'
                "
                v-show="tipoImpAnesteSelected.id !=='U'"
                label="Importe anestesista"
                :prefix="
                  tipoImpAnesteSelected.id !== 'I' 
                    ? ''
                    : '$'
                "
                dense
                outlined
                :rules="
                  tipoImpAnesteSelected.id === 'I'
                    ? rules.required.concat(rules.decimalSi)
                    : []
                "
              ></v-text-field>
              <v-select
                v-model="unidadAnes"
                :items="itemUnidades"
                v-show="tipoImpAnesteSelected.id == 'U'"
                item-text="value"
                item-value="id"
                @change="changeImporteEsp"
                outlined
                dense
                return-object
                label="Unidad de anestesista"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              sm="6"
              md="4"
              class="py-0 my-0 d-flex justify-center ml-2"
            >
              <v-select
                v-model="tipoImporteGtoSelected"
                :items="tipoImpGtos"
                return-object
                item-text="value"
                item-value="id"
                @change="changeImporteGto"
                outlined
                dense
                autocomplete="off"
                label="Tipo importe gastos"
              >
              </v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="importeGto"
                label="Importe gastos"
                dense
                :prefix="
                  tipoImporteGtoSelected.id !== 'I' 
                    ? ''
                    : '$'
                "
                :disabled="
                  tipoImporteGtoSelected == 'N' ||
                    tipoImporteGtoSelected.value == 'No factura'
                "
                outlined
                :rules="
                  tipoImporteGtoSelected.id === 'I'
                    ? rules.required.concat(rules.decimalSi)
                    : []
                "
                v-show="tipoImporteGtoSelected.id !=='U'"
              ></v-text-field>
              <v-select
                v-model="unidadGto"
                :items="itemUnidades"
                v-show="tipoImporteGtoSelected.id === 'U'"
                item-text="value"
                item-value="id"
                @change="changeImporteEsp"
                outlined
                dense
                return-object
                label="Unidad de gastos"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeModal"> Cancelar </v-btn>
            <v-btn
              type="submit"
              :disabled="!isFormValid"
              form="form"
              color="primary"
            >
              Aplicar
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-dialog
          v-model="modalBusquedaCodigoDesde"
          v-if="modalBusquedaCodigoDesde"
          @keydown.esc="toggleModalBusquedaCodigoDesde"
          max-width="50%"
          persistent
        >
          <BusquedaCodigoNomenclador
            @toggleModalBusquedaCodigo="toggleModalBusquedaCodigoDesde"
            @findCodigo="findCodigoDesde"
            :nomencladorObject="nomencladorSelected"
          ></BusquedaCodigoNomenclador>
        </v-dialog>
        <!-- modal para buscar codigo hasta -->
        <v-dialog
          v-model="modalBusquedaCodigoHasta"
          v-if="modalBusquedaCodigoHasta"
          @keydown.esc="toggleModalBusquedaCodigoHasta"
          max-width="50%"
          persistent
        >
          <BusquedaCodigoNomenclador
            @toggleModalBusquedaCodigo="toggleModalBusquedaCodigoHasta"
            @findCodigo="findCodigoHasta"
            :nomencladorObject="nomencladorSelected"
          ></BusquedaCodigoNomenclador>
        </v-dialog>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import BusquedaCodigoNomenclador from "@/components/modules/afiliaciones/afiliados/BusquedaCodigoNomenclador.vue";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  name: "NuevaPractica",
  directives: { mask },
  components: { CurrencyInput, BusquedaCodigoNomenclador },
  props: {
    tablaPrac: {
      type: Object
    },
    idTab: {
      type: Number
    },
    nuevaTab: { type: Object }
  },
  data: () => ({
    itemsPrac: null,
    unidadEsp: null,
    codigoDesde: null,
    codigoHasta: null,
    descCodigoHasta: null,
    descCodigoDesde: null,
    modalBusquedaCodigoDesde: false,
    modalBusquedaCodigoHasta: false,
    areaSelected: "A",
    importeEsp: null,
    cantUniEsp: null,
    importeAyudante: null,
    importeAnestesista: null,
    importeGto: null,
    searchIcon: enums.icons.SEARCH,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    search: "",
    itemUnidadesEsp: [],
    unidadAyu: null,
    itemUnidadesAyu: [],
    unidadAnes: null,
    itemUnidadesAnes: [],
    unidadGto: null,
    itemUnidadesGto: [],
    itemUnidades: [],
    porcentajeAumento: null,
    tipoimporteEspSelected: "N",
    tipoImpEsp: [
      {
        id: "N",
        value: "No factura"
      },
      {
        id: "U",
        value: "Unidad"
      },
      {
        id: "I",
        value: "Importe"
      }
    ],
    tipoImporteAyuSelected: "N",
    tipoImpAyu: [
      {
        id: "N",
        value: "No factura"
      },
      {
        id: "U",
        value: "Unidad"
      },
      {
        id: "I",
        value: "Importe"
      }
    ],
    tipoImpAnesteSelected: "N",
    tipoImpAnestesia: [
      {
        id: "N",
        value: "No factura"
      },
      {
        id: "U",
        value: "Unidad"
      },
      {
        id: "I",
        value: "Importe"
      }
    ],
    tipoImporteGtoSelected: "N",
    tipoImpGtos: [
      {
        id: "N",
        value: "No factura"
      },
      {
        id: "U",
        value: "Unidad"
      },
      {
        id: "I",
        value: "Importe"
      }
    ],
    itemArea: [
      {
        id: "A",
        value: "Ambulatorio"
      },
      {
        id: "I",
        value: "Internaciones"
      },
      {
        id: "D",
        value: "Dual"
      }
    ],
    tipoRedondeoSelected: { id: 0 },
    tipoRedondeo: [
      {
        id: 0,
        value: "Sin redondeo"
      },
      {
        id: 1,
        value: "Unidad"
      },
      {
        id: 2,
        value: "Decena"
      }
    ],

    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    nomencladorItems: [],
    nomencladorSelected: null,
    isFormValid: false,
    formEditTitle: "Editar tabla de facturación",
    newTitle: "Nueva tabla de facturación",
    rules: rules,
    text: "",
    dataPractica: {},
    headersPractica: [
      {
        text: "Nomenclador",
        value: "nomenclador",
        sortable: false
      },
      {
        text: "Código desde",
        value: "codigoDesde",
        sortable: false,
        align: "end"
      },
      {
        text: "Código hasta",
        value: "codigoHasta",
        sortable: false,
        align: "end"
      },
      {
        text: "Área",
        value: "area",
        sortable: false,
        align: "end"
      },
      {
        text: "Especialista",
        value: "especialidad",
        sortable: false,
        align: "end"
      },
      {
        text: "Anestesista",
        value: "anestesia",
        sortable: false
      },
      {
        text: "Ayudante",
        value: "ayudante",
        sortable: false,
        align: "end"
      },
      {
        text: "Gastos",
        value: "gastos",
        sortable: false,
        align: "end"
      }
    ],
    headersHeredarArancel: [
      {
        text: "Unidad",
        value: "unidad",
        sortable: false
      },
      {
        text: "Valor",
        value: "valor",
        sortable: false
      }
    ],
    isLoadingDetalle: false,
    idCuenta: null,
    tablaIdFac: null,
    ctaFId: null,
    idTabla: null,
    detallePractica: [],
    ver: null,
    dataToExport: {
      formatoName: "Proceso importacion modelo",
      data: [
        {
          ["Agente"]: "170300 (Campo requerido)",
          ["Periodo"]: "202209 (Campo requerido)",
          ["Concepto"]: "Percepción de IIBB (Campo requerido)",
          ["Importe"]: "1702.25 (Campo requerido)",
          ["Grupo familiar"]: "(Campo opcional)",
          ["Periodo a devengar"]: "(Campo opcional)"
        }
      ]
    }
  }),
  created() {
    this.nuevaTab
    this.idTabla = this.nuevaTab.tablaId ?? this.nuevaTab.tabId;
    this.setNomencladores();
    this.setUnidades();
    if (this.tablaPrac === {}) {
      this.newTablaFac();
    } else {
      this.setTablaFac();
    }
  },
  methods: {
    ...mapActions({
      nuevoPlan: "configConvenios/nuevoPlan",
      getUnidadesByTabla: "prestadores/getUnidadesByTabla",
      saveNuevaCtaFac: "prestadores/saveNuevaCtaFac",
      getTablasFacturacion: "prestadores/getTablasFacturacion",
      getPracticaById: "prestadores/getPracticaById",
      getDescByNomencladorYCodigo: "afiliaciones/getDescByNomencladorYCodigo",
      heredarTablaFac: "prestadores/heredarTablaFac",
      getFormatoProcesoImportacionXUnicaVez:
        "devengamientos/getFormatoProcesoImportacionXUnicaVez",
      getNomencladores: "prestadores/getNomencladores",
      setAlert: "user/setAlert"
    }),
    async setUnidades() {
      const unidades = await this.getUnidadesByTabla(this.idTabla);
      // this.itemUnidadesEsp = unidades;
      // this.itemUnidadesAyu = unidades;
      // this.itemUnidadesAnes = unidades;
      // this.itemUnidadesGto = unidades;
      // Edit: unifico todo en un array ya que todos son iguales
      this.itemUnidades = unidades;
    },
    async applyPractica() {
      this.dataPractica = {
        ctaFacId: !this.tablaPrac ? 0
          : this.tablaPrac.ctaFacId,
        ctaId: this.tablaPrac?this.tablaPrac.ctaId : 0,
        nomId: this.nomencladorSelected.id,
        nomenclador: this.nomencladorSelected.value,
        codigoDesde: parseInt(this.codigoDesde),
        codigoHasta: parseInt(this.codigoHasta),
        area: this.areaSelected,
        especialidad:
          (this.importeEsp ? this.importeEsp : 0) ||
          (this.unidadEsp ? this.unidadEsp.id : null),
        ayudante: (this.importeAyudante ? this.importeAyudante : 0) || 
        (this.unidadAyu ? this.unidadAyu.id : null) ,
        anestesia: (this.importeAnestesista ? this.importeAnestesista : 0) ||
        (this.unidadAnes ? this.unidadAnes.id : null),
        gastos: (this.importeGto ? this.importeGto : 0) ||
        (this.unidadGto ? this.unidadGto.id : null),
        codigoEsp:
          this.tipoimporteEspSelected.value == "No factura"
            ? "N"
            : this.tipoimporteEspSelected.value == "Unidad"
            ? "U"
            : "I",
        codigoAyu:
          this.tipoImporteAyuSelected.value == "No factura"
            ? "N"
            : this.tipoImporteAyuSelected.value == "Unidad"
            ? "U"
            : "I",
        codigoAne:
          this.tipoImpAnesteSelected.value == "No factura"
            ? "N"
            : this.tipoImpAnesteSelected.value == "Unidad"
            ? "U"
            : "I",
        codigoGto:
          this.tipoImporteGtoSelected.value == "No factura"
            ? "N"
            : this.tipoImporteGtoSelected.value == "Unidad"
            ? "U"
            : "I"
      };

     this.tablaIdFac = this.nuevaTab.tabId
        ? this.nuevaTab.tabId
        : this.nuevaTab.tablaId;
      const res = await this.saveNuevaCtaFac({
        tabId: this.tablaIdFac,
        ctaFac: this.dataPractica
      });

      if (res === true) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
      }
      this.$emit("setPractica");
      this.closeModal();
    },
    async setNomencladores() {
      const nomencladores = await this.getNomencladores();
      this.nomencladorItems = nomencladores;
    },
    changeNomenclador() {
      this.codigoDesde = null;
      this.descCodigoDesde = null;
      this.codigoHasta = null;
      this.descCodigoHasta = null;
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async setTablaFac() {
      const idDetalle = this.tablaPrac.ctaFacId;
      if (idDetalle != null) {
        const response = await this.getPracticaById(idDetalle);
        this.detallePractica = response;
        this.areaSelected = this.detallePractica.area;
        this.nomencladorSelected = this.nomencladorItems.find(x => x.id == this.detallePractica.nomenclador);
        this.codigoDesde = this.detallePractica.codigoDesde;
        this.descCodigoDesde = this.detallePractica.codigoDesdeDescripicion;
        this.codigoHasta = this.detallePractica.codigoHasta;
        this.descCodigoHasta = this.detallePractica.codigoHastaDescripicion;
        this.tipoimporteEspSelected = this.tipoImpEsp.find(x => x.id === this.detallePractica.codigoEsp);
        this.tipoImporteAyuSelected = this.tipoImpAyu.find(x => x.id === this.detallePractica.codigoAyu);
        this.tipoImpAnesteSelected = this.tipoImpAnestesia.find(x => x.id === this.detallePractica.codigoAne);
        this.tipoImporteGtoSelected = this.tipoImpGtos.find(x => x.id === this.detallePractica.codigoGto);
        
        const unidadesConvertidas = JSON.parse(JSON.stringify(this.itemUnidades));
        
        this.unidadEsp = this.tipoimporteEspSelected.id === 'U' ? 
        unidadesConvertidas.find(x => x.id === this.detallePractica.especialidad): null;
        this.unidadAyu = this.tipoImporteAyuSelected.id === 'U' ? 
        unidadesConvertidas.find(x => x.id === this.detallePractica.ayudante): null;
        this.unidadAnes = this.tipoImpAnesteSelected.id === 'U' ?
        unidadesConvertidas.find(x => x.id === this.detallePractica.anestesia): null;
        this.unidadGto = this.tipoImporteGtoSelected.id === 'U' ? 
        unidadesConvertidas.find(x => x.id === this.detallePractica.gastos): null;

        this.importeEsp = this.tipoimporteEspSelected.id === 'I' ? this.detallePractica.especialidad.toString().replace(".", ",") : null;
        this.importeAyudante = this.tipoImporteAyuSelected.id === 'I' ? this.detallePractica.ayudante.toString().replace(".", ",") : null
        this.importeAnestesista = this.tipoImpAnesteSelected.id === 'I' ? this.detallePractica.anestesia.toString().replace(".", ",") : null;
        this.importeGto = this.tipoImporteGtoSelected.id === 'I' ? this.detallePractica.gastos.toString().replace(".", ",") : null
      }
    },

    async newTablaFac() {
      this.formEditTitle = this.newTitle;
    },
    async buscarCodigoDesde() {
      if (this.codigoDesde) {
        const data = {
          nomenclador: this.nomencladorSelected.id,
          codigo: this.codigoDesde
        };
        const response = await this.getDescByNomencladorYCodigo(data);
        if (response.nombre) {
          this.descCodigoDesde = response.nombre;
        } else {
          this.descCodigoDesde = null;
        }
      } else {
        this.descCodigoDesde = null;
      }
    },
    async buscarCodigoHasta() {
      if (this.codigoHasta) {
        const data = {
          nomenclador: this.nomencladorSelected.id,
          codigo: this.codigoHasta
        };
        const response = await this.getDescByNomencladorYCodigo(data);
        if (response.nombre) {
          this.descCodigoHasta = response.nombre;
        } else {
          this.descCodigoHasta = null;
        }
      } else {
        this.descCodigoHasta = null;
      }
    },
    findCodigoDesde(codigoSelected) {
      this.codigoDesde = codigoSelected.nomCod;
      this.descCodigoDesde = codigoSelected.nombre;
    },
    toggleModalBusquedaCodigoDesde() {
      this.modalBusquedaCodigoDesde = !this.modalBusquedaCodigoDesde;
    },
    findCodigoHasta(codigoSelected) {
      this.codigoHasta = codigoSelected.nomCod;
      this.descCodigoHasta = codigoSelected.nombre;
    },
    toggleModalBusquedaCodigoHasta() {
      this.modalBusquedaCodigoHasta = !this.modalBusquedaCodigoHasta;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal() {
      this.$emit("closeModalPractica");
    },
    changeImporteEsp() {
      if (this.tipoimporteEspSelected.value === "No factura") {
        this.importeEsp = null;
      }
    },
    changeImporteAyu() {
      if (this.tipoImporteAyuSelected.value === "No factura") {
        this.importeAyudante = null;
      }
    },
    changeImporteAne() {
      if (this.tipoImpAnesteSelected.value === "No factura") {
        this.importeAnestesista = null;
      }
    },
    changeImporteGto() {
      if (this.tipoImporteGtoSelected.value === "No factura") {
        this.importeGto = null;
      }
    }
  }
};
</script>
<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
