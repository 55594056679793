<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="primary--text">{{ title }}</span>
      </v-card-title>
      <v-form
        v-model="isFormValid"
        ref="select-codigo"
        id="select-codigo"
        @submit.prevent="selectCodigo()"
      >
        <v-row class="p-3 pb-0" @keypress.enter="filterCodigo">
          <v-col cols="6" sm="12" md="4" class="py-0">
            <v-text-field
              v-model.trim="codigoSelect"
              label="Código"
              dense
              clearable
              outlined
              v-mask="'#########'"
              :disabled="descCodigoSelect !== null && descCodigoSelect !== ''"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="12" md="8" class="py-0">
            <v-text-field
              v-model.trim="descCodigoSelect"
              label="Descripción de código"
              dense
              clearable
              outlined
              :disabled="codigoSelect !== null && codigoSelect !== ''"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2" class="py-1 text-left">
            <v-btn
              color="primary"
              :disabled="!isFormValid"
              @click="filterCodigo"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>
        <!-- Tabla de codigos encontrados -->
        <v-row>
          <v-col>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              v-if="filterApplied"
              :items="codigosResult"
              :loading="loading"
              dense
              show-select
              single-select
              v-model="codigoSelected"
              item-key="nomCod"
            >
            </v-data-table>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal()"> Cerrar </v-btn>
          <v-btn
            color="primary"
            type="submit"
            ref="select-codigo"
            :disabled="codigoSelected.length == 0"
          >
            Seleccionar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
export default {
  name: "BusquedaCodigoNomenclador",
  props: {
    nomencladorObject: {
      type: Object,
      default: null
    }
  },
  directives: { mask },
  data() {
    return {
      title: "Búsqueda de código de práctica",
      isFormValid: false,
      codigoSelected: [],
      codigosResult: [],
      codigoSelect: null,
      descCodigoSelect: null,
      filterApplied: false,
      loading: false,
      headers: [
        {
          text: "Código de práctica",
          align: "start",
          sortable: true,
          value: "nomCod"
        },
        {
          text: "Descripción",
          sortable: false,
          value: "nombre"
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      getDescCodigoByNomenclador: "afiliaciones/getDescCodigoByNomenclador",
      setAlert: "user/setAlert"
    }),
    selectCodigo() {
      this.$emit("toggleModalBusquedaCodigo");
      this.$emit("findCodigo", this.codigoSelected[0]);
    },
    closeModal() {
      this.$emit("toggleModalBusquedaCodigo");
    },
    async filterCodigo() {
      if (
        (this.descCodigoSelect != null && this.descCodigoSelect != "") ||
        (this.codigoSelect != null && this.codigoSelect != "")
      ) {
        this.loading = true;
        const data = {
          nomenclador: this.nomencladorObject.id,
          codigo: this.codigoSelect ? this.codigoSelect : this.descCodigoSelect
        };
        try {
          this.filterApplied = true;
          const response = await this.getDescCodigoByNomenclador(data);
          this.codigosResult = response;
          this.loading = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
