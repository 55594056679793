<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="applyArancel()"
        >
          <v-row>
            <v-col cols="6" md="3" class="ml-3">
              <v-autocomplete
                v-model="unidadArancelSelected"
                :items="itemsUnidad"
                label="Unidad"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                return-object
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" md="3" class="ml-2">
              <currency-input
                v-model="valorArancel"
                label="Valor"
                class="theme--light.v-input input"
                :options="currencyOptions"
                :rules="rules.required"
              ></currency-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Aplicar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import getFormatPrice from "@/utils/helpers/getFormatPrice";

export default {
  name: "NuevoArancel",
  directives: { mask },
  props: {
    itemsAranceles: { type: Array },
    tablas: { type: Object },
    tablaAran: { type: Object }
  },
  components: { CurrencyInput },
  data: () => ({
    searchIcon: enums.icons.SEARCH,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    search: "",
    porcentajeAumento: null,
    valorArancel: null,
    unidadArancelSelected: null,
    itemsUnidad: [],
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 9999999999
      },
      precision: 4, // Establecer la precisión en 4 decimales
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: false,
      useGrouping: false
    },

    tablasLoading: false,
    opcionSelected: null,
    isFormValid: false,
    formEditTitle: "Editar tabla de aranceles",
    newTitle: "Nueva tabla de aranceles",
    calendarIcon: enums.icons.CALENDAR,
    btnIsLoading: false,
    rules: rules,
    tablaNombre: null,
    loading: false,
    loadingProcesarBtn: false,
    isLoadingHeredar: false,
    comprobantesAGenerar: [],
    excelData: [],
    modalExportarExcel: false,
    archivoEnProceso: false,
    file: null,
    registrosError: [],
    text: "",
    dataPractica: {},
    fileName: null,
    formatoTitle: "Formato de archivo",
    headers: [],
    datosFormatoArchivo: [],
    showSeeFormatModal: false,
    isLoadingAranceles: false,
    itemsHeredarArancel: [],
    headersAranceles: [
      {
        text: "Unidad",
        value: "unidad",
        sortable: false
      },
      {
        text: "Valor",
        value: "valor",
        sortable: false,
        align: "end"
      }
    ],
    detallePractica: [],
    isLoadingDetalle: false,
    pagination: null,
    nomTab: "",
    ctaFacId: null,
    newPractica: null,
    mostrarTablaArancel: true,
    mostrarTablaCtaFac: false,
    arregloDeEntradas: [],
    arreglo: [],
    idCta: null,
    arregloDeAranceles: [],
    registroArancel: [],
    newAran: {},
    importeTablas: [],
    idAraUni: null,
    aranceles: {},
    uniId: null,
    arancel: null,
    aranUniId: null,
    idAran: null
  }),
  created() {
    //   if (this.valorArancel != null){
    //     this.currencyOptions.valueRange.max = this.valorArancel;
    // }
    this.idAran = this.tablas?.araId;
    this.setUnidades();
    // Agrego un timeout para que puedan cargar las unidades efectivamente
    if (this.tablaAran) {
      setTimeout(() => {
        this.setTablaAran(this.tablaAran);
      }, 800);
    } else {
      this.newTablaAran();
    }
  },
  methods: {
    ...mapActions({
      fetchUnidadesAranceles: "prestadores/fetchUnidadesAranceles",
      saveNuevoArancel: "prestadores/saveNuevoArancel",
      setAlert: "user/setAlert"
    }),
    setTablaAran(obj) {
      this.valorArancel = obj.valor
      // this.valorArancel = parseFloat(
      //   obj.valor.replace(".", "").replace(",", ".")
      // );
      this.aranUniId = obj.araUniId;
      this.unidadArancelSelected = this.itemsUnidad.find(
        x => x.id === obj.unidadId
      );
    },
    addDecimals(x) {
      return getFormatPrice(x);
    },
    async newTablaAran() {
      this.formEditTitle = this.newTitle;
    },
    async setUnidades() {
      const resp = await this.fetchUnidadesAranceles();
      this.itemsUnidad = resp;
    },
    async applyArancel() {
      this.aranceles = {
        araUniId: this.aranUniId,
        unidadId: this.unidadArancelSelected.id,
        unidad: this.unidadArancelSelected.value,
        valor: this.valorArancel
      };
      const res = await this.saveNuevoArancel({
        tabId: this.tablas.tablaId ?? this.tablas.tabId,
        ctaFac: this.aranceles
      });
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
      }
      this.$emit("setAranceles");
      this.closeModal();
    },
    closeModal() {
      this.$emit("closeModalArancel");
    }
  }
};
</script>
<style scoped></style>
